import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from "../images/cdixon-avatar.png"

import headerStyles from "./header.module.css"

const Header = ({ siteTitle }) => {
  return <header className={headerStyles.header}>
    <nav className={headerStyles.nav}>
      <Link to="/">
        <div className={headerStyles.navLeft}>
          <img
            src={logo}
            className={headerStyles.navLeftImage}
            alt="Logo"
          />
          <div className={headerStyles.navLeftBrand}>{siteTitle}</div>
        </div>
      </Link>
      <ul className={headerStyles.navRight}>
        <li className={headerStyles.navList}>
          <Link className={headerStyles.linkStyle} to="/blog">
            Blog
          </Link>
        </li>
        <li className={headerStyles.navList}>
          <a
            className={headerStyles.linkStyle}
            href="https://twitter.com/cdixon"
          >
            Twitter
          </a>
        </li>
        <li className={headerStyles.navList}>
          <Link className={headerStyles.linkStyle} to="/about">
            About
          </Link>
        </li>
      </ul>
    </nav>
  </header>
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
